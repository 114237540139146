import React, { useRef, useState,useEffect } from "react";
import "./index.css";
import CryptoJS from "crypto-js";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactHtmlParser from "react-html-parser";


const Contact = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const form = useRef();
  const [isSuccess, setIsSuccess] = useState(false);
  useEffect(() => {
    fetch(process.env.REACT_APP_API_URL + "/contact")
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setData(data);
        setIsLoading(false);
      })
      .catch((error) => {
        setError(error);
        setIsLoading(false);
      });
  }, []);

  if (isLoading) {
    return <div> </div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

// E-posta adresi formatını doğrulayan fonksiyon
const isValidEmail = (email) => {
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailPattern.test(email);
};
const isValidPhoneNumber = (phoneNumber) => {
  const phonePattern = /^[0-9]{10}$/;
  return phonePattern.test(phoneNumber);
};
  const handleSubmit = async (e) => {
    e.preventDefault();
    const fromEmail = form.current.elements.from.value;
    const phoneNumber = form.current.elements.tel.value;
  
    if (!isValidEmail(fromEmail)) {
      toast.error("Lütfen geçerli bir e-posta adresi giriniz.", { autoClose: 4500 });
      return;
    }
  
    if (!isValidPhoneNumber(phoneNumber)) {
      toast.error("Lütfen geçerli bir telefon numarası giriniz.", { autoClose: 4500 });
      return;
    }
    if (!isButtonDisabled) {
      setIsButtonDisabled(true);

      const encryptedText = CryptoJS.AES.encrypt(
        uuidv4(),
        process.env.REACT_APP_SSK
      ).toString();

      const FD = new FormData(form.current);
      FD.append("accessToken", encryptedText);

      const obj = {
        message: FD.get("message"),
        userInfo: "Firma Adı: " +FD.get("firmaName") + " Yetkili Adı: " + FD.get("userInfoName") + " " + FD.get("userInfoSurname"),
        from: FD.get("from"),
        subject: FD.get("subject"),
        tel: FD.get("tel"),
        ownerName: "osiwebmail@gmail.com",
        to: "crewier@crewier.com",
        host: "smtp.gmail.com",
        service: "gmail",
        accessToken: encryptedText,
      };
      try {
        const res = await axios.post("https://nodemailer.3wweb.org/", obj, {
          auth: {
            username: "OSICrew_Node_Mailler",
            password: "1786oSi?173",
          },
        });
        if (res) {
          setIsSuccess(true);
          toast.success("Mesajınız gönderilmiştir.", { autoClose: 4500 });
          // console.log(res);
        }
      } catch (error) {
        // console.log(error);
        // console.log(obj);
        toast.error("Mesajınız gönderilememiştir.", { autoClose: 4500 });
      }

      setTimeout(() => {
        setIsButtonDisabled(false);
      }, 3000);
    }
  };

  return (
    <><ToastContainer/> <div className="container-fluid mt-4 d-flex justify-content-center">
      <div className="row mt-4 col-lg-12">
        <div className="col-lg-5 col-12 mt-lg-0 mt-4">
          <div className="cardSlider h-100">
            <div className="card card-blur w-100">
              <div className="card-body d-flex flex-column justify-content-center">
              <form ref={form} onSubmit={handleSubmit}>
                <div className="col-12 mt-3">
                      <input
                        className="w-100"
                        type="text"
                        name="firmaName"
                        placeholder="Firma Adı"
                      ></input>
                    </div>
                  <div className="d-flex justify-content-between mt-3">
                    <div className="col-5">
                      <input
                        className="w-100"
                        type="text"
                        name="userInfoName"
                        placeholder="Yetkili Ad"
                      ></input>
                    </div>
                    <div className="col-5">
                    <input
                        className="w-100"
                        type="text"
                        name="userInfoSurname"
                        placeholder="Soyad"
                      ></input>
                    </div>
                  </div>
                    <div className="col-12 mt-3">
                      <input
                        className="w-100"
                        type="text"
                        name="tel"
                        placeholder="Telefon"
                      ></input>
                    </div>
                    <div className="col-12 mt-3">
                      <input
                        className="w-100"
                        type="text"
                        name="from"
                        placeholder="E-Posta Adresi"
                      ></input>
                    </div>
                  
                  <div className="col-12 mt-3"> <input
                        className="w-100"
                        type="text"
                        name="subject"
                        placeholder="Konu"
                      ></input></div>
                  <div className="col-12 mt-4">
                    <textarea
                      className="w-100"
                      rows="6"
                      name="message"
                      placeholder="Mesaj"
                    ></textarea>
                    <button
                      className="btn-crewier mt-3"
                      id="send_btn"
                      disabled={isButtonDisabled}
                    >
                      Gönder
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-7 col-12 mt-lg-0 mt-4">
          <div className="cardSlider h-100 ">
            <div className="card card-blur w-100">
              <div className="card-body">
                <div>
                  <iframe
                    className="map-main"
                    src=                      {data[0]?.map ? ReactHtmlParser(data[0].map) : "İçerik bulunamadı."}

                    allowFullScreen
                    title="map"
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                  />
                </div>
                <div className="row col-12 mt-4">
                  <div className="col-lg-6 col-12 mt-lg-0 mt-4">
                    <h6>Adres</h6>
                    <h6>
                    {data[0]?.adres ? ReactHtmlParser(data[0].adres) : "İçerik bulunamadı."}

                    </h6>
                  </div>
                  <div className="col-lg-6 col-12 mt-lg-0 mt-4">
                    <h6>İletişim</h6>
                    <h6>
                    <a href={`tel:${data[0]?.tel || ''}`}>
  {data[0]?.tel ? ReactHtmlParser(data[0].tel) : "İçerik bulunamadı."}
</a>
<br />
<br />
<a href={`mailto:${data[0]?.mail || ''}`}>
  {data[0]?.mail ? ReactHtmlParser(data[0].mail) : "İçerik bulunamadı."}
</a>

                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div></>
   
  );
};

export default Contact;
