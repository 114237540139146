import React,{useState,useEffect} from "react";
import img1 from "../../assets/gallery/three_d_icon/img_growth.png";
import img2 from "../../assets/gallery/three_d_icon/img_chart.png";
import img3 from "../../assets/gallery/three_d_icon/img_money_tree.png";
import CardSwiper from "./cardSwiper";
import CardSwiperTwo from "./cardSwiperTwo";
import img5 from "../../assets/gallery/img_main/_0021_check.png";
import ReactHtmlParser from "react-html-parser";

const CardGrid = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetch(process.env.REACT_APP_API_URL + "/card")  
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setData(data);
        setIsLoading(false);
      })
      .catch((error) => {
        setError(error);
        setIsLoading(false);
      });
  }, []);

  if (isLoading) {
    return <div> </div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <>
      <div className="transition-bg-main">
        <div className=" transition-bg"></div>
      </div>
      <div className="container-fluid d-flex justify-content-center flex-column align-items-center">
        <div className="row col-lg-12 ">
          <div className="col-lg-4 col-12 mt-0 ">
            <div className="cardSlider h-100">
              <div className="card-blur w-100 card">
                <div className="card-body d-flex flex-column justify-content-end">
                {data[1]?.info ? ReactHtmlParser(data[1].info) : "İçerik bulunamadı."}

                  <a href=                      {data[1]?.button_link ? ReactHtmlParser(data[1].button_link) : "İçerik bulunamadı."}
                  >
                    <button className="btn-crewier">Daha Fazla</button>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className=" col-lg-8 col-12 mt-lg-0 mt-4">
            <div className="cardSlider h-100">
              <div className="card-blur w-100 card">
                <div className="card-body d-flex flex-column justify-content-end">
                  <div className="d-flex">
                    <div className="d-flex flex-column justify-content-end">
                    {data[2]?.info ? ReactHtmlParser(data[2].info) : "İçerik bulunamadı."}

                      <a href={data[2]?.button_link ? ReactHtmlParser(data[2].button_link) : "İçerik bulunamadı."}>
                        <button className="btn-crewier">Daha Fazla</button>
                      </a>
                    </div>
                    <div className="d-lg-block d-md-block d-none">
                      <img className="w-100" src={img1} alt="crewier"></img>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-lg-5 mt-4 col-12">
          {" "}
          <CardSwiperTwo />{" "}
        </div>

        <div className="row mt-4 col-12">
          <div className="col-xl-8  mt-lg-0 px-sm-0-c">
            <div className="cardSlider h-100 m-0">
              <div className="card-blur w-100 card">
                <div className="card-body d-flex flex-column justify-content-end">
                  <div className="col-lg-6">
                    <h3 className="card-title">
                      <b>Hizmetlerimiz</b>
                    </h3>
                    <p className="card-text">
                      Müşterilerimizin dijital dünyada öne çıkmalarını sağlamak
                      için her alanda uzman ekibimizle, işletmelerin dijital
                      ihtiyaçlarını karşılamayı hedefliyoruz. Siz de dijital
                      varlığınızı güçlendirmek ve işinizi bir üst seviyeye
                      taşımak için bizimle iletişime geçin.
                    </p>
                  </div>
                  <a href="/hizmetlerimiz">
                    <button className="btn-crewier mb-4 mt-4">
                      Daha Fazla
                    </button>
                  </a>
                  <CardSwiper />
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-4  d-flex justify-content-between align-items-center flex-column mt-xl-0 mt-4 gap-3">
            <div className="row col-lg-12">
              <div className="cardSlider h-100 m-0">
                <div className="card-blur w-100 card">
                  <div className="card-body d-flex flex-column justify-content-end mt-lg-5">
                    <div className="d-flex">
                      <div className="d-flex flex-column justify-content-end mt-lg-5">
                      {data[3]?.info ? ReactHtmlParser(data[3].info) : "İçerik bulunamadı."}

                        <a href={data[3]?.button_link ? ReactHtmlParser(data[3].button_link) : "İçerik bulunamadı."}>
                          <button className="btn-crewier">Daha Fazla</button>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row col-lg-12">
              <div className="cardSlider h-100 m-0">
                <div className="card-blur w-100 card">
                  <div className="card-body d-flex flex-column justify-content-end mt-lg-5">
                    <div className="d-flex">
                      <div className="d-flex flex-column justify-content-end mt-lg-5">
                      {data[4]?.info ? ReactHtmlParser(data[4].info) : "İçerik bulunamadı."}

                        <a href={data[4]?.button_link ? ReactHtmlParser(data[4].button_link) : "İçerik bulunamadı."}>
                          <button className="btn-crewier">Daha Fazla</button>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      
        <div
          className="row
       mt-4 col-lg-12"
        >
          <div className="col-lg-6 col-12  ">
            <div className="cardSlider h-100">
              <div className="card-blur w-100 card">
                <div className="card-body d-flex flex-column justify-content-end">
                  <div className="d-flex">
                    <div className="col-lg-5 d-flex flex-column justify-content-end">
                    {data[5]?.info ? ReactHtmlParser(data[5].info) : "İçerik bulunamadı."}

                      <a href={data[5]?.button_link ? ReactHtmlParser(data[5].button_link) : "İçerik bulunamadı."}>
                        <button className="btn-crewier">Daha Fazla</button>
                      </a>
                    </div>
                    <div className="col-lg-7">
                      <img className="w-100" src={img3} alt="crewier"></img>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-12 mt-lg-0 mt-4">
            <div className="cardSlider h-100">
              <div className="card-blur w-100 card">
                <div className="card-body d-flex flex-column justify-content-end">
                  <div className="d-flex">
                    <div className="col-lg-5 d-flex flex-column justify-content-end">
                    {data[6]?.info ? ReactHtmlParser(data[6].info) : "İçerik bulunamadı."}

                      <a href={data[6]?.button_link ? ReactHtmlParser(data[6].button_link) : "İçerik bulunamadı."}>
                        {" "}
                        <button className="btn-crewier">Daha Fazla</button>
                      </a>
                    </div>
                    <div className="col-lg-7">
                      <img className="w-100" src={img2} alt="crewier"></img>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row col-lg-12  mt-lg-4  d-flex justify-content-center">
            <div className="col-12 mt-xl-0 mt-4 position-relative">
              <div className="cardSlider h-100">
                <div className="card-blur w-100 card">
                  <div className="card-body d-flex flex-column justify-content-end">
                    <div className="d-xl-flex">
                      <div className="d-flex justify-content-center">
                        <img
                          className="three_icon_corporate-two"
                          src={img5}
                          alt="crewier"
                        ></img>
                      </div>
                      <div className="d-flex flex-column justify-content-end alibaba-inner-top">
                       <h5>
                       {data[7]?.info ? ReactHtmlParser(data[7].info) : "İçerik bulunamadı."}

                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
    </>
  );
};

export default CardGrid;
