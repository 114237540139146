import React,{useEffect,useState} from "react";
import BannerText from "../../components/banner/bannerText";
import img2 from "../../assets/gallery/three_d_icon/img_launch.png";
import img3 from "../../assets/gallery/three_d_icon/img_target.png";
import "./index.css";
import Header from "../../wrappers/header";
import Footer from "../../wrappers/footer";
import { Helmet } from "react-helmet";
import ReactHtmlParser from "react-html-parser";

const AboutUs = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetch(process.env.REACT_APP_API_URL + "/about")
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setData(data);
        setIsLoading(false);
      })
      .catch((error) => {
        setError(error);
        setIsLoading(false);
      });
  }, []);

  if (isLoading) {
    return <div> </div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }
  const metaDescriptionTag = document.querySelector('meta[name="description"]');
  if (metaDescriptionTag) {
    metaDescriptionTag.setAttribute(
      "content",
      "Alibaba.com'da satış yapmak ve üye olmak isteyen işletmelere stratejik çözümler sunuyoruz!"
    );
  } else {
    const newMetaTag = document.createElement("meta");
    newMetaTag.name = "description";
    newMetaTag.content =
      "Alibaba.com'da satış yapmak ve üye olmak isteyen işletmelere stratejik çözümler sunuyoruz!";
    document.head.appendChild(newMetaTag);
  }
  return (
    <div className="page-about-us bg-about-us">
      <Helmet>
        <title>Hakkımızda | Crewier</title>
        <link rel="canonical" href={`https://www.crewier.com/hakkimizda`} />
        <link rel="icon" href="/favicon.ico" />
      </Helmet>
      <Header />
      <BannerText pageTitle="Hakkımızda" />
      <h1 className="d-none">Hakkımızda Crewier</h1>
      <div className="container-fluid pb-5">
        <div className=" row col-lg-12 mt-4 page-about-us-one">
          <div className="col-lg-6 col-md-6 col-12 position-relative">
            <div className="cardSlider h-100">
              <div className="card-blur w-100 card">
                <div className="card-body d-flex flex-column justify-content-end">
                  <div className="d-flex">
                    <div className="d-flex flex-column justify-content-end ">
                      <h3 className="card-title">
                        <b>Misyon</b>
                      </h3>
                      <p className="card-text">
                      {data[0]?.info ? ReactHtmlParser(data[0].info) : "İçerik bulunamadı."}
                      </p>
                    </div>
                    <div>
                      <img
                        className="three_icon_corporate"
                        src={process.env.REACT_APP_API_URL + `/uploads/${data[0].img}`}
                        alt="crewier"
                      ></img>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6  col-md-6 col-12 mt-lg-0 mt-md-0 mt-4 position-relative">
            <div className="cardSlider h-100">
              <div className="card-blur w-100 card">
                <div className="card-body d-flex flex-column justify-content-end">
                  <div className="d-flex">
                    <div className="d-flex flex-column justify-content-end">
                      <h3 className="card-title">
                        <b>Vizyon</b>
                      </h3>
                      <p className="card-text">
                      {data[1]?.info ? ReactHtmlParser(data[1].info) : "İçerik bulunamadı."}

                      </p>
                    </div>
                    <div>
                      <img
                        className="three_icon_corporate"
                        src={process.env.REACT_APP_API_URL + `/uploads/${data[1].img}`}
                        alt="crewier"
                      ></img>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row col-lg-12 mt-4">
          <div className="col-xl-4 col-lg-12 col-12 ">
            <div className="cardSlider h-100">
              <div className="card-blur w-100 card">
                <div className="card-body d-flex flex-column justify-content-start pb-lg-5 pb-md-5 pb-0">
                  <div className="d-flex pb-lg-5 pb-md-5 pb-0">
                    <div className="d-flex flex-column justify-content-end pb-lg-5 pb-md-5 pb-0">
                      <p className="card-text">
                      {data[2]?.info ? ReactHtmlParser(data[2].info) : "İçerik bulunamadı."}

                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-md-6 col-lg-6 col-12 mt-xl-0 mt-lg-4 mt-4 position-relative">
            <div className="cardSlider h-100">
              <div className="card-blur w-100 card">
                <div className="card-body d-flex flex-column justify-content-start">
                  <div className="d-flex">
                    <div className="d-flex flex-column justify-content-end ">
                      <p className="card-text">
                      {data[3]?.info ? ReactHtmlParser(data[3].info) : "İçerik bulunamadı."}

                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-md-6  col-lg-6 col-12 mt-xl-0 mt-lg-4 mt-4 position-relative">
            <div className="cardSlider h-100">
              <div className="card-blur w-100 card">
                <div className="card-body d-flex flex-column justify-content-start">
                  <div className="d-flex">
                    <div className="d-flex flex-column justify-content-end ">
                      <p className="card-text">
                      {data[4]?.info ? ReactHtmlParser(data[4].info) : "İçerik bulunamadı."}

                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default AboutUs;
