import React from 'react'
import "./index.css"

const BannerText = ({ pageTitle }) => {
  const outlineTexts = Array(6).fill(<span className="outline">{pageTitle} </span>);
  const totalTexts = [...outlineTexts, <span className="filled">{pageTitle}</span>, ...outlineTexts];

  return (
    <div className="banner_text">
      <div className="scrolling-text">
        {totalTexts.map((text, index) => (
          <React.Fragment key={index}>
            {text}
          </React.Fragment>
        ))}
      </div>
    </div>
  )
}

export default BannerText
