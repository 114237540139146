import React from "react";
import gif from "../../../assets/gallery/img_main/earth_anim_vertical_02.gif";
import "./index.css";
const MobileWorld = () => {
  return (
    <div className="d-flex justify-content-center bg-black">
      <img src={gif} className="gif-world" alt="crewier"></img>
    </div>
  );
};

export default MobileWorld;
