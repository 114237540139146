import React from "react";
import Layout from "../../layout";
import CanvasWorld from "../../components/homePage/canvas";
import CardSlider from "../../components/cards/cardSlider";
import CardGrid from "../../components/cards/cardGrid";
import Blog from "../../components/homePage/blog";
import Contact from "../../components/homePage/contact";
import Faq from "../../components/homePage/faq";
import MobileWorld from "../../components/homePage/mobileWorld";
import { Helmet } from "react-helmet";
import "./index.css";

const HomePage = () => {
  const metaDescriptionTag = document.querySelector('meta[name="description"]');
  if (metaDescriptionTag) {
    metaDescriptionTag.setAttribute(
      "content",
      "Crewier, Alibaba.com üzerinden satış yapmak ve üye olmak isteyen işletmeler için stratejik çözümler ve partnerlik sunar."
    );
  } else {
    const newMetaTag = document.createElement("meta");
    newMetaTag.name = "description";
    newMetaTag.content =
      "Crewier, Alibaba.com üzerinden satış yapmak ve üye olmak isteyen işletmeler için stratejik çözümler ve partnerlik sunar.";
    document.head.appendChild(newMetaTag);
  }
  return (
    <Layout>
     <Helmet>
        <title>Alibaba Resmi İş Ortağı - Crewier</title>
        <link rel="canonical" href={`https://www.crewier.com`} />
        <link rel="icon" href="/favicon.ico" />
      </Helmet>
      <>
        <h1 className="d-none">Alibaba Resmi İş Ortağı - Crewier</h1>
        <div className="canvas-container">
          <div className="desktop-world">
            <CanvasWorld />
          </div>
          <div className="mobile-world">
            <MobileWorld />
          </div>

          <div className="card-slider">
            <CardSlider />
          </div>
        </div>
        <div className="bg-home"> <CardGrid />
        <Blog />
        <Contact />
        <Faq /></div>
       
      </>
    </Layout>
  );
};

export default HomePage;
