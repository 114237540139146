import React from 'react'
import Header from '../wrappers/header'
import Footer from '../wrappers/footer'
import PropTypes from 'prop-types';

const Layout = ({children}) => {
  return (
    <>
    <Header />
    {children}
    <Footer />
    </>
  )
}

Layout.propTypes = {
    children: PropTypes.any,
};

export default Layout