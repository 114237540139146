import React, { useState } from 'react';
import './MobileMenuIcon.css';

const MobileMenuIcon = ({ onClick }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
    if (onClick) {
      onClick();
    }
  };

  return (
    <div className="menu-icon" onClick={toggleMenu}>
      <svg xmlns="http://www.w3.org/2000/svg" width="30" height="24" viewBox="0 0 30 24">
        <path
          d={isOpen ? "M16.48,94.824a5.739,5.739,0,0,1-2.092-.315c-1.075-.458-1.337-1.434-.838-2.98l-.089-.038L10,99.308l.089.038c.954-1.305,2.384-1.241,5.315-1.23H29.959L30,98.025,26.717,94.82Z" : "M6.48,94.824a5.739,5.739,0,0,1-2.092-.315c-1.075-.458-1.337-1.434-.838-2.98l-.089-.038L0,99.308l.089.038c.954-1.305,2.384-1.241,5.315-1.23H29.959L30,98.025,26.717,94.82Z"}
          transform={isOpen ? "translate(-0.72 -75.346)" : "translate(0 -75.346)"}
          fill="#f8f8f8"
          className="menu-icon-path"
        />
        <path
          d={isOpen ? "M36.49,49.078a5.75,5.75,0,0,1-2.092-.315c-1.075-.458-1.337-1.434-.838-2.98l-.089-.038-3.46,7.817.089.038c.954-1.3,2.384-1.241,5.315-1.23h19.2l.041-.092-3.283-3.205Z" : "M36.49,49.078a5.75,5.75,0,0,1-2.092-.315c-1.075-.458-1.337-1.434-.838-2.98l-.089-.038-3.46,7.817.089.038c.954-1.3,2.384-1.241,5.315-1.23h19.2l.041-.092-3.283-3.205Z"}
          transform={isOpen ? "translate(-25.371 -37.672)" : "translate(-24.651 -37.672)"}
          fill="#f8f8f8"
          className="menu-icon-path"
        />
        <path
          d={isOpen ? "M56.512,3.332a5.743,5.743,0,0,1-2.092-.315c-1.075-.458-1.337-1.434-.838-2.98L53.492,0l-3.46,7.816.089.038c.954-1.3,2.384-1.241,5.315-1.23H79.271l.041-.092L76.029,3.328Z" : "M66.512,3.332a5.743,5.743,0,0,1-2.092-.315c-1.075-.458-1.337-1.434-.838-2.98L63.492,0l-3.46,7.816.089.038c.954-1.3,2.384-1.241,5.315-1.23H79.271l.041-.092L76.029,3.328Z"}
          transform={isOpen ? "translate(-50.032)" : "translate(-49.312)"}
          fill="#f8f8f8"
          className="menu-icon-path"
        />
      </svg>
    </div>
  );
};

export default MobileMenuIcon;