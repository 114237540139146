import React, { Suspense, useRef } from "react";
import { Canvas, useFrame, useLoader } from "@react-three/fiber";
import { TextureLoader } from "three";
import { OrbitControls, Stars } from "@react-three/drei";
import * as THREE from "three";
import { Leva, useControls } from 'leva';
import { Perf } from "r3f-perf";


//textures
import CloudsEarth from "../../../assets/gallery/homePage/threedWorld/clouds_earth.webp";
import EarthNightLights from "../../../assets/gallery/homePage/threedWorld/earth_night_lights.webp";
import Earth from "../../../assets/gallery/homePage/threedWorld/earth.webp";
import EarthBump from "../../../assets/gallery/homePage/threedWorld/earth_bump.webp";
import EarthLandOcean from "../../../assets/gallery/homePage/threedWorld/earth_land_ocean.webp";

const ThreedWorld = () => {
  const [cloudsEarth, earthNightLights, earth, earthBump, earthLandOcean] =
    useLoader(TextureLoader, [
      CloudsEarth,
      EarthNightLights,
      Earth,
      EarthBump,
      EarthLandOcean,
    ]);

  const earthRef = useRef();
  const cloudsRef = useRef();
  const mouseX = useRef(0);
  const mouseY = useRef(0);

  // Leva controls
  const {
    rotationSpeed,
    cloudOpacity,
    frontlightIntensity,
    backlightIntensity,
    earthMetalness,
    earthRoughness,
    rotationX,
    rotationY,
    rotationZ,
    sensitivity,
    enableZoom,
    zoomSpeed,
    enablePan,
    panSpeed,
    enableRotate,
    rotateSpeed,
    perfDisplay,
  } = useControls({
    rotationSpeed: { value: 0, min: 0, max: 1 },
    cloudOpacity: { value: 0.5, min: 0, max: 1 },
    frontlightIntensity: { value: 50, min: 0, max: 100 },
    backlightIntensity: { value: 0, min: 0, max: 100 },
    earthMetalness: { value: 0.4, min: 0, max: 1 },
    earthRoughness: { value: 0.7, min: 0, max: 1 },
    rotationX: { value: -0.4, min: -Math.PI, max: Math.PI },
    rotationY: { value: -2.3, min: -Math.PI, max: Math.PI },
    rotationZ: { value: 0.12, min: -Math.PI, max: Math.PI },
    sensitivity: { value: 0.5, min: 0.1, max: 1 },
    enableZoom: false,
    zoomSpeed: { value: 0.2, min: 0, max: 1 },
    enablePan: false,
    panSpeed: { value: 0.2, min: 0, max: 1 },
    enableRotate: false,
    rotateSpeed: { value: 0.6, min: 0, max: 1 },
    perfDisplay:false
  });

  const handleMouseMove = (event) => {
    mouseX.current = (event.clientX / window.innerWidth) * 2 - 1;
    mouseY.current = (event.clientY / window.innerHeight) * 2 + 1;
  };

  useFrame(({ clock }) => {
    const elapsedTime = clock.getElapsedTime();
    earthRef.current.rotation.x = rotationX + mouseY.current * sensitivity;
    earthRef.current.rotation.y = elapsedTime * rotationSpeed + rotationY + mouseX.current * sensitivity;
    earthRef.current.rotation.z = rotationZ;
    cloudsRef.current.rotation.x = rotationX + mouseY.current * sensitivity;
    cloudsRef.current.rotation.y = elapsedTime * rotationSpeed + rotationY + mouseX.current * sensitivity;
    cloudsRef.current.rotation.z = rotationZ;
  });

  React.useEffect(() => {
    window.addEventListener('mousemove', handleMouseMove);
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  return (
    <>
    

      {/* Light */}
      <pointLight position={[2, 0, 4.5]} color="#f6f3ea" intensity={frontlightIntensity} />
      <pointLight position={[-4, 0, -5]} color="#f6f3ea" intensity={backlightIntensity} />

      {/* Stars */}
      <Stars
        radius={300}
        depth={60}
        count={20000}
        factor={7}
        saturation={0}
        fade={true}
      />

      {/* Clouds Mesh */}
      <mesh ref={cloudsRef} scale={2.2} position={[0, 0, 0]}>
        <sphereGeometry args={[1.005, 128, 128]} />
        <meshPhongMaterial
          map={cloudsEarth}
          opacity={cloudOpacity}
          depthWrite={true}
          transparent={true}
          side={THREE.DoubleSide}
        />
      </mesh>

      {/* Earth Mesh */}
      <mesh ref={earthRef} scale={2.2} rotation={[0, Math.PI / 1, 0]} position={[0, 0, 0]}>
        <sphereGeometry args={[1, 128, 128]} />
        <meshPhongMaterial specularMap={EarthBump} />
        <meshStandardMaterial
          map={earth}
          normalMap={earthBump}
          metalness={earthMetalness}
          roughness={earthRoughness}
        />
        <OrbitControls
          enableZoom={enableZoom}
          enablePan={enablePan}
          enableRotate={enableRotate}
          zoomSpeed={zoomSpeed}
          panSpeed={panSpeed}
          rotateSpeed={rotateSpeed}
        />
      </mesh>

      <Perf
      
        className="custom-perf"
        position={"bottom-left"}
        showGraph={true}
        colorBlind={false}
        trackGPU={true}
        style= {perfDisplay ? {backgroundColor: "black" } : {display: "none"}}  
      />
    </>
  );
};

export default ThreedWorld;
