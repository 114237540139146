import React, { useState, useEffect } from "react";
import "./index.css";
import BannerText from "../../components/banner/bannerText";
import Header from "../../wrappers/header";
import Footer from "../../wrappers/footer";
import img from "../../assets/gallery/img_main/_0015_export.png";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import "swiper/css/mousewheel";
import { FreeMode, Pagination, Mousewheel } from "swiper/modules";
import { Helmet } from "react-helmet";
import ReactHtmlParser from "react-html-parser";

const ECommerce = () => {
  const [data, setData] = useState([]);
  const [dataTwo, setDataTwo] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchData = async (url, setter) => {
    try {
      const response = await fetch(url);
      if (!response.ok) throw new Error("Network response was not ok");
      const data = await response.json();
      setter(data);
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData(process.env.REACT_APP_API_URL + "/commerce", setData);
    fetchData(process.env.REACT_APP_API_URL + "/card", setDataTwo);
  }, []);

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  const formatTitle = (title) => {
    const words = title.split(" ");
    if (words.length > 1) {
      const lastWord = words.pop();
      const restOfTitle = words.join(" ");
      return (
        <>
          {restOfTitle} <strong>{lastWord}</strong>
        </>
      );
    }
    return title;
  };

  const metaDescriptionTag = document.querySelector('meta[name="description"]');
  if (metaDescriptionTag) {
    metaDescriptionTag.setAttribute(
      "content",
      "E-ticaret (elektronik ticaret), internet üzerinden ürün ve hizmetlerin alım satımının yapıldığı bir ticaret türüdür."
    );
  } else {
    const newMetaTag = document.createElement("meta");
    newMetaTag.name = "description";
    newMetaTag.content =
      "E-ticaret (elektronik ticaret), internet üzerinden ürün ve hizmetlerin alım satımının yapıldığı bir ticaret türüdür.";
    document.head.appendChild(newMetaTag);
  }
  return (

    <div className="d-flex justify-content-center flex-column overflow-x-hidden">
      <Helmet>
        <title>E-Ticaret | Crewier</title>
        <link rel="canonical" href={`https://www.crewier.com/e-ticaret`} />
        <link rel="icon" href="/favicon.ico" />
      </Helmet>
      <Header />
      <div>
      <BannerText pageTitle="E-Ticaret" />
      <div className="container-fluid col-lg-12 d-lg-flex align-items-end justify-content-center bg-1f1f1f">
        <h1 className="d-none">E-Ticaret Crewier</h1>

        <div className="col-lg-6 col-12">
          <div className="cardSlider h-100 ">
            <div className="card-blur w-100 card">
              <div className="card-body d-flex flex-column justify-content-end mt-lg-5 mt-md-5 mt-0">
                <h4 className="card-title mt-lg-5 mt-md-5 mt-0">
                {dataTwo[8]?.info ? ReactHtmlParser(dataTwo[8].info) : "İçerik bulunamadı."}

                </h4>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-md-12 col-12 d-flex justify-content-center  mt-lg-0 mt-4">
          <img
            className="p-xl-0 p-5 img-export-commerce"
          
            src={img}
            alt="crewier"
          ></img>
        </div>
      </div>
      <div className="container-fluid">
        {" "}
        <div className=" row  bg-1f1f1f">
          <div className="p-4">
            <Swiper
              breakpoints={{
                640: {
                  slidesPerView: 2,
                },
                768: {
                  slidesPerView: 2,
                },
                1024: {
                  slidesPerView: 3,
                },
                1025: {
                  slidesPerView: 4,
                },
              }}
              spaceBetween={30}
              freeMode={true}
              pagination={{
                clickable: true,
              }}
              mousewheel={{
                releaseOnEdges: true,
              }}
              modules={[FreeMode, Pagination, Mousewheel]}
              className="mySwiper"
            >
              {data.map((blog, index) => (
                <SwiperSlide key={index}>
                  <div className="cardSlider m-0 pb-5">
                    <div className="card card-blur w-100">
                      <div className="card-body d-flex flex-column justify-content-between ">
                        <div className="mt-1">
                          <div className="col-12 d-flex justify-content-end">
                            <div className="col-7">
                              <img
                                className="w-100"
                                src={process.env.REACT_APP_API_URL + `/uploads/${blog.img}`}
                                alt={blog.title}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="mt-2">
                          <h3
                            className="card-title"
                            style={{ width: "min-content" }}
                          >
                            {formatTitle(blog.title)}
                          </h3>
                          <h6 className="card-title">{blog.info} </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div></div>
      
      <Footer />
    </div>
  );
};

export default ECommerce;
