export function removeTurkishChars(text) {
  const turkishChars = {
    ğ: "g",
    Ğ: "G",
    ü: "u",
    Ü: "U",
    ş: "s",
    Ş: "S",
    ı: "i",
    İ: "I",
    ö: "o",
    Ö: "O",
    ç: "c",
    Ç: "C",
  };

  return text
    .replace(/[ğüşıöçĞÜŞİÖÇ]/g, (match) => turkishChars[match]) // Türkçe karakterleri temizle
    .replace(/[^a-zA-Z0-9 ]/g, "") // Özel karakterleri temizle
    .replace(/\s+/g, "-") // Boşlukları tire ile değiştir
    .toLowerCase(); // Küçük harfe çevir
}
