import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, Pagination, Mousewheel, Keyboard } from "swiper/modules";
import ReactHtmlParser from "react-html-parser";

export default function CardSwiperTwo() {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetch(process.env.REACT_APP_API_URL + "/homeslider")
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setData(data);
        setIsLoading(false);
      })
      .catch((error) => {
        setError(error);
        setIsLoading(false);
      });
  }, []);

  if (isLoading) {
    return <div> </div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div className="swiper-card-main-two">
      <Swiper
        cssMode={true}
        navigation={true}
        pagination={true}
        mousewheel={true}
        loop={true}
        keyboard={true}
        modules={[Navigation, Pagination, Mousewheel, Keyboard]}
        className="mySwiper"
      >
    {data.map((slide, index) => (
  <SwiperSlide key={index}>
    <img src={process.env.REACT_APP_API_URL + `/uploads/${slide.img}`} alt={`Slide ${index + 1}`} />
    <div className={index === 3 ? "overlay-text-end" : "overlay-text"}>
      {slide.info ? ReactHtmlParser(slide.info) : "İçerik bulunamadı."}
    </div>
  </SwiperSlide>
))}


      </Swiper>
    </div>
  );
}
