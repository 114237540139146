import React, { useState, useEffect } from "react";
import ReactHtmlParser from "react-html-parser";

const CardSlider = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetch(process.env.REACT_APP_API_URL + "/card")
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setData(data);
        setIsLoading(false);
      })
      .catch((error) => {
        setError(error);
        setIsLoading(false);
      });
  }, []);

  if (isLoading) {
    return <div> </div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-xl-6 col-12 ">
          <div className="cardSlider">
            <div className="card card-blur">
              <div className="card-body">
                {data[0]?.info
                  ? ReactHtmlParser(data[0].info)
                  : "İçerik bulunamadı."}

                <a
                  href={
                    data[0]?.button_link
                      ? ReactHtmlParser(data[0].button_link)
                      : "İçerik bulunamadı."
                  }
                >
                  <button className="btn-crewier">Daha Fazla</button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardSlider;
