import React, { Suspense } from "react";
import { Canvas } from "@react-three/fiber";
import ThreedWorld from "../threedWorld";

const CanvasWorld = () => {
  return (
    <Canvas className="three_d_world">


      <Suspense fallback={null}>
        <ThreedWorld />
      </Suspense>
      
    </Canvas>
  );
};

export default CanvasWorld;
