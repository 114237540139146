import React, { useState, useEffect } from "react";
import "./index.css";
import BannerText from "../../components/banner/bannerText";
import Header from "../../wrappers/header";
import Footer from "../../wrappers/footer";
import { Helmet } from "react-helmet";
import img1 from "../../assets/gallery/img_main/crewier_post_slider_01.png";
import img2 from "../../assets/gallery/img_main/crewier_post_slider_02.png";
import img3 from "../../assets/gallery/img_main/crewier_post_slider_03.png";
import img4 from "../../assets/gallery/img_main/crewier_post_slider_04.png";
import img5 from "../../assets/gallery/img_main/crewier_post_slider_05.png";
import ReactHtmlParser from "react-html-parser";

const Services = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetch(process.env.REACT_APP_API_URL + "/services")
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setData(data);
        setIsLoading(false);
      })
      .catch((error) => {
        setError(error);
        setIsLoading(false);
      });
  }, []);

  if (isLoading) {
    return <div> </div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const formatTitle = (title) => {
    const words = title.split(" ");
    if (words.length > 1) {
      const lastWord = words.pop();
      const restOfTitle = words.join(" ");
      return (
        <>
          {restOfTitle} <strong>{lastWord}</strong>
        </>
      );
    }
    return title;
  };
  const metaDescriptionTag = document.querySelector('meta[name="description"]');
  if (metaDescriptionTag) {
    metaDescriptionTag.setAttribute(
      "content",
      "Alibaba.com üzerinde en etkin şekilde yer almanız, ürünlerinizi dünya pazarlarına tanıtmanız için gerekli stratejik desteği sunmaktadır."
    );
  } else {
    const newMetaTag = document.createElement("meta");
    newMetaTag.name = "description";
    newMetaTag.content =
      "Alibaba.com üzerinde en etkin şekilde yer almanız, ürünlerinizi dünya pazarlarına tanıtmanız için gerekli stratejik desteği sunmaktadır.";
    document.head.appendChild(newMetaTag);
  }

  return (
    <div className=" d-flex justify-content-center flex-column bg-services">
      <Helmet>
        <title>Hizmetlerimiz | Crewier</title>
        <link rel="canonical" href={`https://www.crewier.com/hizmetlerimiz`} />
        <link rel="icon" href="/favicon.ico" />
      </Helmet>
      <Header />
      <h1 className="d-none">Hizmetlerimiz Crewier</h1>

      <BannerText pageTitle="Hizmetlerimiz" />
      <div className="container-fluid d-flex justify content-center align-items-center flex-column mb-5">
        {" "}
        <div className="row col-lg-12 d-lg-flex d-md-flex">
          <div className="col-lg-5 col-md-5 col-12 mt-lg-0 mt-md-0 mt-5">
            <div className="h-100">
              <div className="cardSlider h-100 m-0">
                <div className="card-blur w-100 card">
                  <div className="card-body d-flex flex-column justify-content-end">
                    {data.length > 0 && (
                      <>
                        <img
                          className="services-img-main"
                          src={process.env.REACT_APP_API_URL + `/uploads/${data[0].img}`}
                          alt={data[0].title}
                        ></img>
                        <h3
                          className="card-title mt-lg-5 mt-md-5 mt-0"
                          style={{ width: "min-content" }}
                        >
                          {formatTitle(data[0].title)}
                        </h3>
                        <p className="card-text">{data[0].info}</p>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-7 col-md-7 col-12 buse mt-lg-0 mt-md-0 mt-5">
            <div className="cardSlider h-100 m-0">
              <div className="card-blur w-100 card ">
                <div className="card-body d-flex justify-content-end">
                  <div className="buse-main d-flex gap-4">
                    {" "}
                    <img src={img1} alt="Image 1"></img>
                    <img src={img2} alt="Image 2"></img>
                    <img src={img3} alt="Image 3"></img>
                    <img src={img4} alt="Image 4"></img>
                    <img src={img5} alt="Image 4"></img>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row col-lg-12 d-lg-flex mt-lg-4 mt-md-4 mt-5  d-md-flex">
          <div className="col-lg-7 col-md-5 col-12 ">
            <div className="cardSlider h-100 m-0">
              <div className="card-blur w-100 card">
                <div className="card-body d-flex flex-column justify-content-end">
                  {data.length > 0 && (
                    <>
                      <img
                        className="services-img-main"
                        src={process.env.REACT_APP_API_URL + `/uploads/${data[2].img}`}
                        alt={data[2].title}
                      ></img>
                      <h3
                        className="mt-lg-5 mt-md-5 mt-0"
                        style={{ width: "min-content" }}
                      >
                        {formatTitle(data[2].title)}
                      </h3>
                      <p className="card-text">{data[2].info}</p>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-5 col-md-7 mt-lg-0 mt-md-0 mt-5">
            <div className="cardSlider h-100 m-0 ">
              <div className="card-blur w-100 card">
                <div className="card-body d-flex flex-column justify-content-end">
                  {data.length > 0 && (
                    <>
                      <img
                        className="services-img-main"
                        src={process.env.REACT_APP_API_URL + `/uploads/${data[1].img}`}
                        alt={data[1].title}
                      ></img>
                      <h3
                        className="mt-lg-5 mt-md-5 mt-0"
                        style={{ width: "min-content" }}
                      >
                        {formatTitle(data[1].title)}
                      </h3>
                      <p className="card-text">
                        {" "}
                        {ReactHtmlParser(data[1].info)}
                      </p>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row col-lg-12 mt-lg-4 mt-md-4 mt-5 d-lg-flex d-md-flex flex-md-wrap">
          <div className="col-xl-3 col-lg-6 col-md-6 col-12">
            <div className="cardSlider h-100 m-0">
              <div className="card-blur w-100 card">
                <div className="card-body d-flex flex-column justify-content-end">
                  {data.length > 0 && (
                    <>
                      <div className="col-12 d-flex justify-content-lg-end">
                        <div className="col-7">
                          {" "}
                          <img
                            className="services-img-five"
                            src={process.env.REACT_APP_API_URL + `/uploads/${data[3].img}`}
                            alt={data[3].title}
                          ></img>
                        </div>
                      </div>{" "}
                      <h3
                        className="card-title mt-lg-0 mt-md-5 mt-0"
                        style={{ width: "min-content" }}
                      >
                        {formatTitle(data[3].title)}
                      </h3>
                      <p className="card-text">{data[3].info}</p>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-6 col-12  mt-lg-0 mt-md-0 mt-5">
            <div className="cardSlider h-100 m-0">
              <div className="card-blur w-100 card">
                <div className="card-body d-flex flex-column justify-content-end">
                  {data.length > 0 && (
                    <>
                      <div className="col-12 d-flex justify-content-lg-end">
                        <div className="col-7 d-flex justify-content-lg-end">
                          {" "}
                          <img
                            className="services-img-main"
                            src={process.env.REACT_APP_API_URL + `/uploads/${data[4].img}`}
                            alt={data[4].title}
                          ></img>
                        </div>
                      </div>
                      <h3
                        className="card-title mt-lg-0 mt-md-5 mt-0"
                        style={{ width: "min-content" }}
                      >
                        {formatTitle(data[4].title)}
                      </h3>
                      <p className="card-text">{data[4].info}</p>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-lg-12 col-md-12 col-12  mt-xl-0 mt-lg-4 mt-md-4 mt-5">
            <div className="cardSlider h-100 m-0">
              <div className="card-blur w-100 card">
                <div className="card-body d-flex flex-column justify-content-end">
                  {data.length > 0 && (
                    <>
                      <div className="col-12 d-flex justify-content-lg-end justify-content-md-end">
                        <div className="col-7 d-flex justify-content-lg-end justify-content-md-end">
                          {" "}
                          <img
                            className="services-img-five"
                            src={process.env.REACT_APP_API_URL + `/uploads/${data[5].img}`}
                            alt={data[5].title}
                          ></img>
                        </div>
                      </div>

                      <h3
                        className="card-title mt-lg-0 mt-md-5 mt-0"
                        style={{ width: "min-content" }}
                      >
                        {formatTitle(data[5].title)}
                      </h3>
                      <p className="card-text">{data[5].info}</p>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row col-lg-12 d-lg-flex mt-lg-4 mt-md-4 mt-5 d-md-flex">
          <div className="col-lg-5 col-md-6 col-12 ">
            <div className="cardSlider h-100 m-0">
              <div className="card-blur w-100 card">
                <div className="card-body d-flex flex-column justify-content-end">
                  {data.length > 0 && (
                    <>
                      <img
                        className="services-img-main"
                        src={process.env.REACT_APP_API_URL + `/uploads/${data[6].img}`}
                        alt={data[6].title}
                      ></img>
                      <h3
                        className="mt-lg-5 mt-md-5 mt-0"
                        style={{ width: "min-content" }}
                      >
                        {formatTitle(data[6].title)}
                      </h3>
                      <p className="card-text">{data[6].info}</p>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-7 col-md-6 mt-lg-0 mt-md-0 mt-5">
            <div className="cardSlider h-100 m-0 ">
              <div className="card-blur w-100 card">
                <div className="card-body d-flex flex-column justify-content-end">
                  {data.length > 0 && (
                    <>
                      <img
                        className="services-img-main"
                        src={process.env.REACT_APP_API_URL + `/uploads/${data[7].img}`}
                        alt={data[7].title}
                      ></img>
                      <h3
                        className="mt-lg-5 mt-md-5 mt-0"
                        style={{ width: "min-content" }}
                      >
                        {formatTitle(data[7].title)}
                      </h3>
                      <p className="card-text">
                        {ReactHtmlParser(data[7].info)}
                      </p>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Services;
