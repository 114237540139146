import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import appData from "../../data/app.json";
import { headerSticky } from "../../utils/header";
import MobileMenuIcon from "./MobileMenuIcon"; // Mobil menü ikonu bileşeni
import mobileLogo from "../../assets/logo/crewier_logo_w_02.svg"
const Header = ({ darkHeader }) => {
  const location = useLocation();
  const navItems = appData.header.menu.map((item) => ({
    ...item,
    classes: "dropdown-link",
  }));

  const [desktopMenu, setDesktopMenu] = useState(false);
  const [headerVisible, setHeaderVisible] = useState(true);
  const [hideHeaderTimeout, setHideHeaderTimeout] = useState(null);
  // console.log(desktopMenu);

  const toggleDesktopMenu = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    const newDesktopMenuState = !desktopMenu;
    setDesktopMenu(newDesktopMenuState);

    if (hideHeaderTimeout) {
      clearTimeout(hideHeaderTimeout);
      setHideHeaderTimeout(null);
    }

    if (newDesktopMenuState) {
      setHeaderVisible(true);
    } else {
      setHeaderVisible(true);
      const timeoutId = setTimeout(() => {
        setHeaderVisible(false);
      }, 1000);
      setHideHeaderTimeout(timeoutId);
    }

    const menuPopup = document.querySelector(".osi-menu-popup");
    const menuButton = document.querySelector(".osi-menu-btn");

    if (newDesktopMenuState) {
      menuPopup.classList.add("menu--visible");
      menuPopup.classList.add("menu--open");
      setTimeout(() => {
        menuButton.parentNode.classList.remove("osi--notouch");
        menuPopup.classList.add("menu--ready");
      }, 500);
    } else {
      menuPopup.classList.remove("menu--ready");
      setTimeout(() => {
        menuPopup.classList.remove("menu--open");
      }, 300);
      menuButton.parentNode.classList.remove("osi--notouch");
    }
  };

  useEffect(() => {
    headerSticky();
  }, []);

  console.log(headerVisible);

  return (
    <header className={darkHeader ? "osi-header" : "osi-header header--white"}>
      <div className="header--builder">
        <div className="container">
          <div className="row d-flex justify-content-between">
            {/* Logo - Büyük ekran */}
            <div className="col-4 col-xs-4 col-sm-4 col-md-4 col-lg-3 align-self-center d-lg-block">
              <div className="osi-logo-image">
                <Link to="/">
                <img className="d-lg-none d-md-none d-block" src={mobileLogo} alt="crewier"></img>
                  <img
                    className="logo--white d-lg-block d-md-block d-none" 
                    src={appData.header.logo.image_white}
                    alt={appData.header.logo.alt}
                  />
                </Link>
              </div>
            </div>
            {/* Menü - Büyük ekran */}
            <div className="col-4 col-xs-4 col-sm-4 col-md-4 col-lg-6 align-self-center text-center d-lg-flex d-md-flex justify-content-lg-center justify-content-md-center justify-content-end">
              <a
                href="#"
                aria-label="crewier-1"
                className={
                  desktopMenu ? "osi-menu-btn btn--active d-lg-block d-md-block d-none" : "osi-menu-btn  d-lg-block d-md-block d-none"
                }
                onClick={toggleDesktopMenu}
              >
                <span />
              </a>
              <div
                className="osi-menu-popup text-start"
                style={{ display: headerVisible ? "block" : "none" }}
              >
                <div className="osi-menu-overlay" />
                <div className="osi-menu-overlay-after" />
                <div className="osi-menu-container osi--noscroll">
                  <div className="container osimenucontainer">
                    <div className="osi-menu">
                      <ul className="osi-menu-nav">
                        {navItems.map((item, key) => (
                          <li
                            key={`header-nav-item-${key}`}
                            className={`${item.classes} ${
                              location.pathname === item.link ? "active" : ""
                            }`}
                          >
                            <Link
                              className="osi-lnk"
                              to={item.link}
                              data-hover={item.label}
                            >
                              {item.label}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Menü - Küçük ekran */}
            <div className="d-lg-none d-md-none d-block w-auto">
              <MobileMenuIcon onClick={toggleDesktopMenu} />
            </div>
            {/* Logo - Küçük ekran */}
            <div className="col-4 col-xs-4 col-sm-4 col-md-4 col-lg-3 align-self-center  d-lg-block d-md-block d-none">
              <div className="osi-logo-image">
                <Link to="/">
                  
                  <img
                    className="logo--white"
                    src={appData.header.logo.image_alibaba}
                    alt={appData.header.logo.alt}
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;