import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import "swiper/css/mousewheel";
import { FreeMode, Pagination, Mousewheel } from "swiper/modules";
import "./index.css";
import { removeTurkishChars } from "../../../utils/urlUtils";
import ReactHtmlParser from "react-html-parser";

const Blog = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetch(process.env.REACT_APP_API_URL + "/blog")
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setData(data);
        setIsLoading(false);
      })
      .catch((error) => {
        setError(error);
        setIsLoading(false);
      });
  }, []);

  if (isLoading) {
    return <div> </div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
      <div className="container-fluid mt-4">
        <div className="row">
          <div className="col-xl-6 col-12 mt-lg-0">
            <div className="p-3">
              <h2 className="card-title"> <b>Blog</b> </h2>
              <h3 className="card-text">
                İşletmenizi büyütmek ve global pazarlarda başarı elde etmek için
                ihtiyaç duyduğunuz rehberlik ve stratejileri keşfedin. Hemen
                inceleyin ve dijital dünyanın fırsatlarını yakalayın!
              </h3>
          <a target="_blank" href="/blog"><button className="btn-crewier">Daha Fazla</button></a>    
            </div>
          </div>
        </div>
        <div className="row">
          <div className="swiper-card-blog p-4">
            <Swiper
             breakpoints={{
              640: {
                slidesPerView: 1,
              },
              768: {
                slidesPerView: 2,
              },
              992: {
                slidesPerView: 3,
              },
              1025: {
                slidesPerView: 4,
              },
            }}
              spaceBetween={30}
              freeMode={true}
              pagination={{
                clickable: true,
              }}
              mousewheel={{
                releaseOnEdges: true,
              }}
              modules={[FreeMode, Pagination, Mousewheel]}
              className="mySwiper"
            >
              {data.map((blog, index) => (
                <SwiperSlide key={index}>
                  <a  href={`/blog-detay/${removeTurkishChars(blog.title)
                        .replace(/\s+/g, "-")
                        .toLowerCase()}`}>  <div className="cardSlider m-0 pb-5">
                    <div className="card card-blur w-100">
                      <div className="card-body d-flex flex-column justify-content-between ">
                        <div className="mt-1">
                          <div className="swiper-blog-img">
                            <img
                              className="w-100"
                              src={process.env.REACT_APP_API_URL + `/uploads/${blog.img}`}
                              alt={blog.title}
                            />
                          </div>
                          <div className="mt-4">
                            <h5 className="card-title"> <b>{blog.title}</b></h5>
                          </div>
                        </div>
                        {/* <h6 className="card-title p-0 m-0">{ReactHtmlParser(blog.info.slice(0,30)+"..")} </h6> */}
                      </div>
                    </div>
                  </div></a>
                
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>
   
  );
};

export default Blog;
